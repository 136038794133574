import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import AuditAssetOverview from "components/audit/AuditAssetOverview"
import Button from "components/reusable/button"
import CategoryFilter from "components/category/category_filter"
import CategoryForm from "components/category/category_form"
import Label from "components/reusable/label"
import Loader from "components/reusable/Loader"
# TODO: Remove this and dependent libs when this file removed
import PopupPortal from "components/reusable/popup_portal"
import PostGroupForm from "components/post_groups/post_group_form"
import { Icon } from "@makeably/creativex-design-system"

# A modal that implements materialize modal and allows react components to use it
# with on open and close functionality
class Modal extends React.Component
  KEY_LEFT = 37
  KEY_RIGHT = 39

  @propTypes =
    content: PropTypes.shape(
      componentName: PropTypes.string # String of component name
      props: PropTypes.object         # Props for component
    )
    contentLocation: PropTypes.string # URL used to fetch content in an ajax fashion
    contentParams: PropTypes.object   # Params to send on request for content
    enableModalNavigation: PropTypes.bool # Enables navigating between modals with arrow keys
    # Called to get the URL to fetch content for the modal at the given idx (this is for prev/next modal navigation)
    getModalContentLocationAtIdx: PropTypes.func
    # We need an id on the modal that is used as an anchor on a link/button
    # outside the context of the modal to handle open action
    id: PropTypes.string.isRequired
    idx: PropTypes.number
    lastIdx: PropTypes.number
    # URL used to fetch content of prev/next modal when navigating between modals
    newContentLocation: PropTypes.string
    newIdx: PropTypes.number
    onClose: PropTypes.func
    onOpen: PropTypes.func
    loadOnOpen: PropTypes.bool
    shrink: PropTypes.bool

  @defaultProps =
    loadOnOpen: false

  classes: ->
    classNames(
      'modal',
      'modal--old',
      'materialize-modal',
      'modal--shrink': @props.shrink,
      @props.className
    )

  constructor: (props) ->
    super(props)

    @state =
      idx: @props.idx
      isLoadingData: false

    if @props.content
      @state.componentProps = @props.content.props
      @state.componentType = @props.content.componentName
      @state.hasComponent = true
    else
      @state.componentProps = ''
      @state.componentType = ''
      @state.hasComponent = false

  # This functionality is used when we launch a modal through a callback or when navigating between modals
  componentDidUpdate: (prevProps, prevState) ->
    if prevProps.contentLocation isnt @props.contentLocation or prevProps.contentParams isnt @props.contentParams
      @getModalContent(@props.contentLocation, @state.idx)
    else if prevProps.newContentLocation isnt @props.newContentLocation and @state.hasComponent
      # When navigating between modals, we need to load the new content
      # (the modal's own contentLocation/contentParams don't update but the info needed to get the next
      # modal's content does - newContentLocation and newIdx props will update)
      @getModalContent(@props.newContentLocation, @props.newIdx)

  # This is called after component is mounted in DOM
  # This functionality is used when launching a modal through the data-target behavior
  componentDidMount: ->
    # Set modal function listeners
    modalOptions = {}

    modalOptions['ready'] = =>
      if @props.onOpen
        @props.onOpen()
      if @props.loadOnOpen
        @getModalContent(@props.contentLocation, @state.idx)

    modalOptions['complete'] = =>
      if @props.onClose
        @props.onClose()
      # NB: if you load content on open, then we want to clear content so that there
      #     isn't a flicker of content change
      if @props.loadOnOpen
        @resetContent()
      $('.lean-overlay').remove()

    # Initialize remote Modal Launch on element click
    $("*[data-target='#{@props.id}']").leanModal(modalOptions)

    # Load content if we have a location
    if @props.contentLocation and !@props.loadOnOpen
      @getModalContent(@props.contentLocation, @state.idx)

  getModalContent: (contentLocation, newIdx) ->
    return if @state.isLoadingData

    @setState(isLoadingData: true)

    $.get(
      contentLocation,
      @props.contentParams
    ).done((response) =>
      @setState({
        componentProps: response.props
        componentType: response.componentName
        hasComponent: true
        idx: newIdx
      }, @addEventListener)
    ).fail( ->
      Materialize.toast("Oops... something went wrong.", 5000)
    ).always( =>
      @setState(isLoadingData: false)
    )

  addEventListener: ->
    if @props.enableModalNavigation and @state.hasComponent
      window.addEventListener('keyup', @navigateModals)

  navigateModals: (e) =>
    # Modal can be navigated via clickable arrow buttons or via arrow keys
    return unless _.contains(['left', 'right'], e) || _.contains([KEY_LEFT, KEY_RIGHT], e.keyCode)

    if e is 'left' || e.keyCode is KEY_LEFT
      return if @state.idx == 0
      newIdx = @state.idx - 1
    else # key right
      return if @state.idx == @props.lastIdx
      newIdx = @state.idx + 1

    # Get the URL of the prev/next modal to fetch its content.
    # If the modal is inside an infinite scroll container (ex. Creatives page), we can pass
    # in the index of the prev/next modal to retrieve its new contentLocation. When the
    # modal gets the newContentLocation via props, we make the call to get the new content.
    # (see componentDidUpdate and getModalContent)
    @props.getModalContentLocationAtIdx(newIdx)

  closeModal: =>
    # Clear the content and remove the event listener to prevent weird navigating
    # behavior if multiple modals are listening at the same time
    $("##{@props.id}").closeModal()
    @resetContent()
    window.removeEventListener('keyup', @navigateModals)

  resetContent: ->
    @setState(
      idx: @props.idx,
      hasComponent: false
    )

  # If prev/next modal navigation is enabled, we want to show clickable left/right arrows
  # to let users know of this capability. These have the same functionality as navigating
  # with the arrow keys.
  renderArrowNavButton: (direction) =>
    return unless @props.enableModalNavigation

    return if (direction is 'left' and @state.idx is 0) || (direction is 'right' and @state.idx is @props.lastIdx)

    classes = classNames(
      'btn-floating',
      'navLeftBtn': direction is 'left',
      'navRightBtn': direction is 'right'
    )

    iconName = if direction is 'left' then 'arrowLeft' else 'arrowRight'

    `<a className={classes} onClick={() => this.navigateModals(direction)} >
      <Icon color="white" name={iconName} />
    </a>`

  render: ->
    content =
      if @state.isLoadingData
        `<Loader/>`
      else if @state.hasComponent
        componentProps = @state.componentProps
        componentProps['closeModal'] = @closeModal
        componentProps['renderArrowNavButton'] = @renderArrowNavButton
#       Add componentTypes when necessary, we only want to import components that are used
        switch @state.componentType
          when 'AuditAssetOverview'
            # Scorecard
            React.createElement(AuditAssetOverview, componentProps)
          when 'CategoryFilter'
            React.createElement(CategoryFilter, componentProps)
          when 'CategoryForm'
            React.createElement(CategoryForm, componentProps)
          when 'Label'
            React.createElement(Label, componentProps)
          when 'PostGroupForm'
            React.createElement(PostGroupForm, componentProps)
      else
        @props.children

    `<PopupPortal>
      <div id={this.props.id} className={this.classes()}>
        <div className="modal-content">
          {content}
        </div>
      </div>
    </PopupPortal>`

export default Modal

import React, {
  useState,
  useMemo,
  useEffect,
} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  Icon,
  MaxWidthText,
  Tooltip,
} from '@makeably/creativex-design-system';
import ChannelLogo from 'components/atoms/ChannelLogo';
import DsTabs from 'components/molecules/DsTabs';
import SafeZoneModal from 'components/organisms/SafeZoneModal';

const viewPropOptions = [
  'Brand Cues',
  'Categories',
  'Compliance',
  'Details',
  'Guidelines',
  'Hypotheses',
  'Versions',
];

const categoriesProps = {
  description: PropTypes.string,
  name: PropTypes.string,
  passed: PropTypes.bool,
};

const propTypes = {
  channel: PropTypes.string.isRequired,
  channelDisplay: PropTypes.string.isRequired,
  qualityRank: PropTypes.string.isRequired,
  source: PropTypes.shape({
    creativeUrl: PropTypes.string,
    isVideo: PropTypes.bool,
  }).isRequired,
  tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
  onTabChange: PropTypes.func.isRequired,
  altText: PropTypes.string,
  campaignObjective: PropTypes.string,
  categories: PropTypes.arrayOf(
    PropTypes.shape(categoriesProps),
  ),
  copy: PropTypes.string,
  guidelines: PropTypes.arrayOf(
    PropTypes.shape({
      ...categoriesProps,
      definitions: PropTypes.arrayOf(
        PropTypes.shape({
          ...categoriesProps,
          weight: PropTypes.string,
        }),
      ),
      weight: PropTypes.string,
    }),
  ),
  hypotheses: PropTypes.arrayOf(
    PropTypes.shape({
      ...categoriesProps,
      definitions: PropTypes.arrayOf(
        PropTypes.shape({
          ...categoriesProps,
        }),
      ),
    }),
  ),
  index: PropTypes.number,
  performance: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  placements: PropTypes.arrayOf(PropTypes.string),
  platform: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  regulatory: PropTypes.shape({
    rating: PropTypes.string,
    ratingLabel: PropTypes.string,
    rules: PropTypes.arrayOf(
      PropTypes.shape({
        ...categoriesProps,
      }),
    ),
  }),
  score: PropTypes.string,
  snapshots: PropTypes.arrayOf(
    PropTypes.shape({
      details: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        }),
      ),
      version: PropTypes.number,
    }),
  ),
  view: PropTypes.oneOf(viewPropOptions),
};

const defaultProps = {
  altText: undefined,
  campaignObjective: undefined,
  categories: [],
  copy: undefined,
  guidelines: [],
  hypotheses: [],
  index: undefined,
  performance: undefined,
  placements: [],
  platform: undefined,
  regulatory: undefined,
  score: '',
  snapshots: undefined,
  view: 'Details',
};

function isFullyReviewed(guidelines) {
  return guidelines.every(({ passed }) => passed !== null);
}

function renderItem(item, idx) {
  return (
    <div key={idx} className="u-flexRow postDetail-item">
      <div>
        { `${item.label}:` }
      </div>
      <div>{ item.value }</div>
    </div>
  );
}

function renderWeight(weight) {
  if (!weight) return null;
  return <div>{ weight }</div>;
}

function getIconProps({ passed }) {
  if (passed === null) {
    return { name: 'processing' };
  } else if (passed) {
    return {
      color: 'green',
      name: 'checkCircle',
    };
  }
  return {
    color: 'red',
    name: 'exclamationCircle',
  };
}

function renderLabelRow(rule, index, handleViewModal) {
  const {
    name, description, templateUrl,
  } = rule;

  const direction = index % 2 === 0 ? 'right' : 'left';
  return (
    <div className="u-flexRow u-alignCenter">
      <MaxWidthText size="custom" text={name} />
      { description && <Tooltip direction={direction} label={description} /> }
      { templateUrl && (
        <button
          className="safeZone-button"
          type="button"
          onClick={() => handleViewModal(templateUrl)}
        >
          View
        </button>
      ) }
    </div>
  );
}

function renderRegulatoryContent(regulatory, renderRules) {
  if (regulatory === undefined) return null;

  const {
    rating, ratingLabel, rules,
  } = regulatory;
  if (rating === 'unavailable') return null;

  const regClasses = classNames(
    'auditScore',
    `complianceRating--${rating}`,
  );

  return (
    <div>
      <div className="u-flexRow u-justifyFlexEnd u-marginBelowSm">
        <div className={regClasses}>{ ratingLabel }</div>
      </div>
      <div className="postDetail-guidelines">
        { renderRules(rules, false) }
      </div>
    </div>
  );
}

function renderSnapshot(snapshot) {
  const { version, details } = snapshot;

  return (
    <div key={version} className="postDetail-snapshot">
      <b>
        { `Version ${version}` }
      </b>
      { details.map(({ label, value }) => (
        <div className="u-flexRow u-justifySpaceBetween postDetail-snapshotDetail">
          <span>{ label }</span>
          <span>{ value }</span>
        </div>
      )) }
    </div>
  );
}

function AuditPostDetail({
  altText,
  campaignObjective,
  categories,
  channel,
  channelDisplay,
  qualityRank,
  guidelines,
  hypotheses,
  index,
  regulatory,
  score,
  source,
  performance,
  platform,
  tabs,
  onTabChange,
  placements,
  copy,
  snapshots,
  view,
}) {
  const [safeZoneModalOpen, setSafeZoneModalOpen] = useState(false);
  const [templateUrl, setTemplateUrl] = useState('');
  const [currentView, setCurrentView] = useState(view);
  const tabOptions = useMemo(() => {
    const initialTabs = tabs.slice();
    if (snapshots) {
      initialTabs.push('Versions');
    }
    return initialTabs;
  }, [tabs, snapshots]);

  useEffect(() => {
    setCurrentView(view);
  }, [view]);

  const handleTabClick = (tabView) => {
    onTabChange(tabView);
    setCurrentView(tabView);
  };

  const handleViewModal = (url) => {
    setTemplateUrl(url);
    setSafeZoneModalOpen(true);
  };

  const scoreClasses = classNames(
    'auditScore',
    `auditScore--${qualityRank}`,
  );

  const renderHeader = () => {
    const headerTabs = tabOptions.map((tab) => ({
      label: tab,
      onClick: handleTabClick,
    }));

    return (
      <div>
        <div className="postDetail-header">
          <div className="postDetail-channel">
            <ChannelLogo
              channel={channel}
              showPaid={false}
              size="size-32"
            />
            <h5>{ channelDisplay }</h5>
          </div>
        </div>
        <DsTabs currentTab={currentView} tabs={headerTabs} variant="button" />
      </div>
    );
  };

  const renderDefinition = (definition, idx) => {
    const {
      passed,
      name,
      description,
      templateUrl: defUrl,
      weight,
    } = definition;

    const passFailDotClasses = classNames(
      'pass-fail-dot',
      { 'pass-fail-dot--passed': passed },
      { 'pass-fail-dot--failed': passed === false },
    );

    const direction = index % 2 === 0 ? 'right' : 'left';
    const renderedWeight = renderWeight(weight);

    return (
      <div key={idx} className="postDetail-guidelineDetails">
        <div className="u-flexRow u-alignCenter">
          <span className={passFailDotClasses}>{ passed }</span>
          <span>{ name }</span>
          { description && (
            <Tooltip
              direction={direction}
              label={description}
            />
          ) }
          { defUrl && (
            <button
              className="safeZone-button"
              type="button"
              onClick={() => handleViewModal(defUrl)}
            >
              View
            </button>
          ) }
        </div>
        <div>{ renderedWeight }</div>
      </div>
    );
  };

  const renderGuideline = (guideline, idx, hasWeight) => {
    const label = renderLabelRow(guideline, index, handleViewModal);
    const icon = (
      <div>
        <Icon {...getIconProps(guideline)} />
      </div>
    );

    const definitions = guideline.definitions || [];

    return (
      <div key={idx} className="postDetail-guidelineRow">
        <div className="postDetail-guidelineDetails">
          { label }
          { hasWeight && definitions.length === 0 && renderWeight(guideline.weight) }
          { icon }
        </div>
        { definitions.map((definition, defIdx) => renderDefinition(definition, defIdx)) }
      </div>
    );
  };

  const renderRules = (rules, hasWeight) => (
    <div className="postDetail-guidelinesTable u-flexColumn">
      <div className="postDetail-guidelineHeader">
        <div>Name</div>
        { hasWeight && <div>Weight</div> }
        <div>Status</div>
      </div>
      { Array.isArray(rules) && rules.map(
        (guideline, idx) => renderGuideline(guideline, idx, hasWeight),
      ) }
    </div>
  );

  const renderCategory = (category, idx) => {
    const icon = getIconProps(category);

    return (
      <div key={idx} className="postDetail-guidelineRow postDetail-guidelineDetails">
        { renderLabelRow(category, index, handleViewModal) }
        <div>
          <Icon {...icon} />
        </div>
      </div>
    );
  };

  const renderContent = () => {
    switch (currentView) {
      case 'Details':
        return (
          <div className="postDetail-content">
            { campaignObjective && (
              <div className="postDetail-campaignObjective">
                <h5>Campaign Objective</h5>
                <span>{ campaignObjective }</span>
              </div>
            ) }
            { placements.length > 0 && (
              <div className="postDetail-placements">
                <h5>Placements</h5>
                { placements.map((placement) => <span>{ placement }</span>) }
              </div>
            ) }
            <div className="postDetail-description">
              <h5>Description</h5>
              <span>{ copy }</span>
            </div>
            { altText && (
              <div className="postDetail-description">
                <h5>Alt Text</h5>
                <span>{ altText }</span>
              </div>
            ) }
            { performance && (
              <div className="postDetail-performance">
                <h5>Performance</h5>
                { performance.map(renderItem) }
              </div>
            ) }
            { platform && (
              <div className="postDetail-performance">
                <h5>Platform</h5>
                { platform.map(renderItem) }
              </div>
            ) }
          </div>
        );

      case 'Guidelines':
        return (
          <div>
            <div className="u-flexRow u-justifyFlexEnd u-marginBelowSm">
              { isFullyReviewed(guidelines) && <div className={scoreClasses}>{ score }</div> }
            </div>
            <div className="postDetail-guidelines">
              { renderRules(guidelines, true) }
            </div>
          </div>
        );

      case 'Categories':
      case 'Brand Cues':
        return (
          <div className="postDetail-guidelines">
            { categories.map(renderCategory) }
          </div>
        );

      case 'Compliance':
        return renderRegulatoryContent(regulatory, renderRules);

      case 'Versions':
        return (
          <div>
            { snapshots.map(renderSnapshot) }
          </div>
        );

      case 'Hypotheses':
        return (
          <div className="postDetail-guidelines">
            { renderRules(hypotheses, false) }
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="postDetail">
      { renderHeader() }
      { renderContent() }
      <SafeZoneModal
        isOpen={safeZoneModalOpen}
        source={source}
        templateUrl={templateUrl}
        onClose={() => setSafeZoneModalOpen(false)}
      />
    </div>
  );
}

AuditPostDetail.propTypes = propTypes;
AuditPostDetail.defaultProps = defaultProps;

export default AuditPostDetail;

import React from 'react';
import PropTypes from 'prop-types';
import NavDropdown from 'components/navigation/NavDropdown';

const propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  selected: PropTypes.string.isRequired,
};

function CreativesActions({ options, selected }) {
  const renderSortOptions = () => {
    const sanitizedOptions = NavDropdown.sanitize(options);
    const activeOption = sanitizedOptions.find((opt) => opt.link === selected);

    const dropdownProps = {
      activeOption,
      menuPosition: 'bottom-left',
      options: sanitizedOptions,
      trackingName: 'Sort By Dropdown',
    };

    return <NavDropdown {...dropdownProps} />;
  };

  return (
    <div className="row">
      <div className="col s12 u-flexRow u-justifyFlexEnd">
        { renderSortOptions() }
      </div>
    </div>
  );
}

CreativesActions.propTypes = propTypes;

export default CreativesActions;

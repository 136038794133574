import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Icon } from '@makeably/creativex-design-system';
import ChannelLogo from 'components/atoms/ChannelLogo';
import MixpanelTracker from 'components/utils/MixpanelTracker';

const propTypes = {
  creative: PropTypes.shape({
    isVideo: PropTypes.bool,
    url: PropTypes.string,
  }).isRequired,
  id: PropTypes.string.isRequired,
  modalId: PropTypes.string.isRequired,
  quality: PropTypes.shape({
    lowestRank: PropTypes.string,
    maxScore: PropTypes.string,
    minScore: PropTypes.string,
  }).isRequired,
  regulatory: PropTypes.shape({
    rating: PropTypes.string,
  }).isRequired,
  usage: PropTypes.shape({
    channels: PropTypes.arrayOf(PropTypes.string),
    count: PropTypes.number,
    pretest: PropTypes.bool,
  }).isRequired,
};

function getIconDetails(rating) {
  switch (rating) {
    case 'mixed':
      return {
        icon: 'exclamationCircle',
        color: 'orange',
      };
    case 'compliant':
      return {
        icon: 'checkCircle',
        color: 'green',
      };
    case 'noncompliant':
      return {
        icon: 'xCircle',
        color: 'red',
      };
    default:
      return {
        icon: null,
        color: null,
      };
  }
}

function renderScore(quality) {
  if (!quality?.maxScore) return null;

  // eslint-disable-next-line no-undef
  let score = FormatUtil.metricFormat('audit_score', quality.maxScore);
  if (quality.minScore) {
    // eslint-disable-next-line no-undef
    score = `${score} - ${FormatUtil.metricFormat(
      'audit_score',
      quality.minScore,
    )}`;
  }

  const scoreClasses = classNames(
    'auditScore',
    'u-marginLeft',
    `auditScore--${quality.lowestRank}`,
  );

  return <div className={scoreClasses}>{ score }</div>;
}

function renderPretestUsage(pretest) {
  if (!pretest) return null;
  return <div className="auditScore">Pre-Flight</div>;
}

function renderRegulatoryIcon(rating) {
  const { icon, color } = getIconDetails(rating);
  return icon ? <Icon color={color} name={icon} /> : null;
}

function renderRegulatorySection(rating, pretest) {
  if (!rating) return null;

  return (
    <div className="u-flexRow u-flexAlignCenter">
      <Icon name="clipboard" />
      <div className="complianceScore u-marginLeft">
        { renderRegulatoryIcon(rating) }
        { renderPretestUsage(pretest) }
      </div>
    </div>
  );
}

function renderAuditScore(quality, pretest) {
  if (!quality) return null;

  return (
    <div className="auditScore-container u-flexAlignCenter">
      <Icon name="thumbsUp" />
      { renderScore(quality) }
      { renderPretestUsage(pretest) }
    </div>
  );
}

function renderChannels(channels) {
  if (!channels) return null;

  return (
    <div className="u-flexRow u-spaceBetween u-marginAboveSm">
      <div className="auditCreative-channels">
        { channels.map((channel) => <ChannelLogo key={channel} channel={channel} size="size-24" />) }
      </div>
    </div>
  );
}

function AuditCreativeCard({
  creative,
  id,
  modalId,
  quality,
  regulatory,
  usage,
}) {
  const {
    pretest,
    channels,
    count,
  } = usage || {};
  const { isVideo, url } = creative || {};
  const { rating } = regulatory || {};

  const handleCardClick = () => {
    MixpanelTracker.trackButtonClick('Creative Modal');
  };

  const imageContainerClasses = classNames(
    'auditCreative-imageContainer',
    { 'auditCreative-imageContainer--video': isVideo },
  );

  const creativeCardClasses = classNames(
    'auditCreative-card',
    { 'auditCreative-card--lg': quality && rating },
  );

  const detailsClasses = classNames(
    'auditCreative-details',
    { 'auditCreative-details--lg': quality && rating },
  );

  return (
    <button
      className={creativeCardClasses}
      data-target={modalId}
      type="button"
      onClick={handleCardClick}
    >
      <div className={imageContainerClasses}>
        { count > 1 && <div className="auditCreative-usageCount">{ count }</div> }
        { isVideo && (
        <div className="videoIconContainer">
          <Icon name="play" />
        </div>
        ) }
        <img alt={`creativex_id_${id}`} className="auditCreative-image" src={url} />
      </div>
      <div className={detailsClasses}>
        { renderAuditScore(quality, pretest) }
        { renderRegulatorySection(rating, pretest) }
        { renderChannels(channels) }
      </div>
    </button>
  );
}

AuditCreativeCard.propTypes = propTypes;

export default AuditCreativeCard;

import React, {
  useState,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import {
  CloseButton,
  Tabs,
} from '@makeably/creativex-design-system';
import AuditCreative from 'components/audit/AuditCreative';
import AuditPostDetail from 'components/audit/AuditPostDetail';
import MixpanelTracker from 'components/utils/MixpanelTracker';

const categoriesProps = {
  description: PropTypes.string,
  name: PropTypes.string,
  passed: PropTypes.bool,
};

const AuditPostDetailProps = {
  altText: PropTypes.string,
  campaignObjective: PropTypes.string,
  categories: PropTypes.arrayOf(
    PropTypes.shape(categoriesProps),
  ),
  channel: PropTypes.string,
  channelDisplay: PropTypes.string,
  copy: PropTypes.string,
  guidelines: PropTypes.arrayOf(
    PropTypes.shape({
      ...categoriesProps,
      definitions: PropTypes.arrayOf(
        PropTypes.shape({
          ...categoriesProps,
          weight: PropTypes.string,
        }),
      ),
      weight: PropTypes.string,
    }),
  ),
  hypotheses: PropTypes.arrayOf(
    PropTypes.shape({
      ...categoriesProps,
      definitions: PropTypes.arrayOf(
        PropTypes.shape({
          ...categoriesProps,
        }),
      ),
    }),
  ),
  performance: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  placements: PropTypes.arrayOf(PropTypes.string),
  platform: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  qualityRank: PropTypes.string,
  regulatory: PropTypes.shape({
    rating: PropTypes.string,
    ratingLabel: PropTypes.string,
    rules: PropTypes.arrayOf(
      PropTypes.shape(categoriesProps),
    ),
  }),
  score: PropTypes.string,
  snapshots: PropTypes.arrayOf(
    PropTypes.shape({
      details: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        }),
      ),
      version: PropTypes.number,
    }),
  ),
};

const propTypes = {
  auditPostDetails: PropTypes.arrayOf(
    PropTypes.shape(AuditPostDetailProps),
  ).isRequired,
  creativeDetails: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
    }),
  ).isRequired,
  source: PropTypes.string.isRequired,
  video: PropTypes.bool.isRequired,
  challengeUrl: PropTypes.string,
  closeModal: PropTypes.func,
  isModalRender: PropTypes.bool,
  linkUrl: PropTypes.string,
  postUrl: PropTypes.string,
  renderArrowNavButton: PropTypes.func,
  tabs: PropTypes.arrayOf(PropTypes.string),
  thumbnailUrl: PropTypes.string,
  view: PropTypes.string,
};

const defaultProps = {
  challengeUrl: '',
  closeModal: () => {},
  isModalRender: true,
  linkUrl: '',
  postUrl: '',
  renderArrowNavButton: () => {},
  tabs: [],
  thumbnailUrl: '',
  view: '',
};

function AuditAssetOverview({
  auditPostDetails,
  creativeDetails,
  closeModal,
  isModalRender,
  linkUrl,
  challengeUrl,
  postUrl,
  renderArrowNavButton,
  source,
  tabs,
  thumbnailUrl,
  view: initialView,
  video,
}) {
  const [view, setView] = useState(initialView);
  const sourceObj = useMemo(() => ({
    creativeUrl: source,
    isVideo: video,
  }), [source, video]);

  let leftArrow = null;
  let rightArrow = null;
  if (renderArrowNavButton) {
    leftArrow = renderArrowNavButton('left');
    rightArrow = renderArrowNavButton('right');
  }

  const handleTabClick = (tab) => {
    MixpanelTracker.trackTabClick('Modal', view);
    setView(tab);
  };

  const handleDetailsTabClick = () => {
    MixpanelTracker.trackTabClick('Modal Detail', view);
  };

  return (
    <div className="scorecard">
      <AuditCreative
        challengeUrl={challengeUrl}
        creativeDetails={creativeDetails}
        externalUrl={postUrl}
        isVideo={video}
        overviewUrl={linkUrl}
        sourceUrl={source}
        thumbnailUrl={thumbnailUrl}
      />
      { leftArrow }
      <div className="scorecard-details">
        <div className="scorecard-header">
          <div className="scorecard-tabs">
            <Tabs
              tabs={tabs.map((tab) => ({
                label: tab,
                onClick: () => handleTabClick(tab),
              }))}
            />
          </div>
          { isModalRender && (
            <CloseButton
              onClick={closeModal}
            />
          ) }
        </div>
        <div className="scorecard-postDetailsContainer">
          { auditPostDetails.map((detail) => (
            <AuditPostDetail
              source={sourceObj}
              tabs={tabs}
              view={view}
              onTabChange={handleDetailsTabClick}
              {...detail}
            />
          )) }
        </div>
      </div>
      { rightArrow }
    </div>
  );
}

AuditAssetOverview.propTypes = propTypes;
AuditAssetOverview.defaultProps = defaultProps;

export default AuditAssetOverview;
